<template>
  <div class="about">
    <h1>Gründer</h1>
    <h4>Profil:</h4>
    <div style="display: flex;">
      <ul>
        <li>
          Peter Milde, Diplom Kaufmann und Personalfachwirt über 30 Jahre
          Berufserfahrung, davon über 20 Jahre im Beratungsumfeld Healthcare mit
          dem Schwerpunkt Klinken; zuvor insgesamt 10 Jahre in leitenden
          Positionen in den Bereichen Gesundheitswesen (Kliniken,
          Alten-/Seniorenpflege und Betreutes Wohnen), Industrie (Automotive,
          Verkehrstechnik und Elektroindustrie), Groß- und Außenhandel (Eisen
          und Stahl-Industrie), Banken und Versicherungen.
        </li>
      </ul>
      <div style="margin-left: 12px">
        <img src="../../assets/img/Peter-Milde.jpg" width="300" />
        <sub>Peter Milde, Dipl.- Kfm. und Personalfachwirt</sub>
      </div>
    </div>
    <h4>Know-how-Schwerpunkte:</h4>
    <ul>
      <li>
        Organisation / Sanierungs-/ Restrukturierungsprozesse / WS-Ketten in
        Kliniken
      </li>
      <li>
        Industrialisierung im Gesundheitswesen (Prozessoptimierung,
        Aufbau-Ablauforganisation, Wirtschaftlichkeitsanalysen,
        Kostenoptimierung, Effizienzsteigerung, In-/Outsourcing/ BPO)
      </li>
      <li>
        Personalführung und Mitabeiterentwicklung
      </li>
      <li>
        Projektmanagement/Umsetzungsbegleitung
      </li>
      <li>
        Strategieanalyse und –entwicklung
      </li>
      <li>
        Changemanagement
      </li>
      <li>
        Coaching
      </li>
    </ul>
  </div>
</template>
